import { Select } from "antd";
import { useApi, useApiMutation } from "hooks/useApi/useApiHooks";
import { FC, useState } from "react";
import { IPayment } from "../types";
import { queryClient } from "services/client/query.config";
import { IPaymentStatus } from "pages/settings/payment-status/types";
import { useTranslation } from "react-i18next";
import "../style.css";

export interface IPutPaymentStatusProps {
  foundPayment: IPayment | undefined;
}

export const PutPaymentStatus: FC<IPutPaymentStatusProps> = ({
  foundPayment,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: put } = useApiMutation("payment", "put");
  const { data } = useApi<IPaymentStatus[]>("payment-status/all", {
    page: 1,
    limit: 100,
  });

  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    color: string;
  } | null>(
    foundPayment
      ? {
          value: foundPayment.status._id,
          color:
            data?.data?.find((item) => item._id === foundPayment.status._id)
              ?.color || "transparent",
        }
      : null
  );

  return (
    <Select
      value={selectedOption?.value}
      onChange={(value) => {
        const selectedItem = data?.data?.find((item) => item._id === value);
        setSelectedOption({
          value,
          color: selectedItem?.color || "transparent",
        });

        put({
          _id: foundPayment!._id,
          statusId: value,
          comment: foundPayment!.comment,
        }).then(() => queryClient.invalidateQueries(["academic-year"]));
      }}
      placeholder={t("general.not_paid")}
      className="inline-block w-48"
      style={{
        backgroundColor: selectedOption?.color || "transparent",
      }}
      options={data?.data?.map((item) => ({
        label: item.text,
        value: item._id,
        style: { backgroundColor: item.color },
      }))}
    />
  );
};
