import { CommonButton, TextInput } from "components";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ILoginData,
  setLoginData,
  setThemeState,
} from "store/reducers/LoginSlice";
import { useAppDispatch } from "store/storeHooks";
import logo from "assets/images/logo.png";
import api from "services/client/client";
import axios from "axios";

interface ILoginBody {
  login: string;
  password: string;
}
const Login = () => {
  const { control, handleSubmit } = useForm<ILoginBody>();
  const dis = useAppDispatch();
  const navigate = useNavigate();
  const url = window.location.href;
  const { t } = useTranslation();

  const { mutate, status } = useApiMutation<ILoginBody, ILoginData>(
    "/sign-in",
    "post",
    {
      onSuccess(data) {
        dis(setLoginData(data.data));
        const theme = localStorage.getItem("theme");
        dis(setThemeState(theme || "light"));

        toast.success(t("toast_messages.success"));
        localStorage.setItem("token", data.data.token);
        localStorage.setItem(
          "branchId",
          data.data.branchEmployees?.[0]?.branchId
        );
        localStorage.setItem("employeeId", data.data._id);
        localStorage.setItem("i18nextLng", "uz");
        localStorage.setItem("schedule_show", "class");

        navigate("/schedule");
      },
    }
  );

  const submit = (data: ILoginBody) => {
    mutate(data);
  };
  return (
    <div className="h-screen w-screen flex items-center justify-center bg-bgDisabled">
      <div className="bg-bgMain p-8 rounded-xl w-[304px]">
        <form onSubmit={handleSubmit(submit)}>
          <div className="d-flex flex-column">
            <div className="text-center logo_icon">
              <img
                src={logo}
                alt="education"
                className="h-10 w-8 inline-block"
              />
            </div>
            <p className="text-center text-[18px] mt-6">
              {t("general.sign_in")}
            </p>
          </div>
          <main className="mt-8">
            <span>
              <TextInput
                control={control}
                name="login"
                type="text"
                placeholder="Login"
              />
            </span>
            <span className="mt-[14px] block">
              <TextInput
                control={control}
                name="password"
                type="password"
                placeholder="Password"
              />
            </span>
            <span className="mt-[14px] block">
              <CommonButton
                disabled={status === "loading"}
                title="Login"
                type="submit"
                status={status}
                className="main"
                sx={{
                  height: "42px !important",
                  width: "100%",
                }}
              />
            </span>
          </main>
        </form>
      </div>
    </div>
  );
};

export default Login;
