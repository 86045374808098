import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IDatePickerV2Props extends DatePickerProps {
  name: string;
  title?: string;
}

export const DatePickerV2: FC<IDatePickerV2Props> = ({
  name,
  title,
  maxDate,
  minDate,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <div className="w-full">
      {title && (
        <label htmlFor={name} className="mb-2 inline-block cursor-pointer">
          {title}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DatePicker
            {...field}
            {...props}
            id={name}
            className="hover:border-hoverMain cursor-pointer w-full"
            format="DD-MM-YYYY"
            onChange={(date) =>
              field.onChange(date ? dayjs(date.toISOString()) : null)
            }
            value={field.value ? (dayjs(field.value) as any) : undefined}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      />
    </div>
  );
};
