import { FC, useEffect, useState } from "react";
import { TableCell, TableRow, TableV2 } from "components";
import { useApi } from "hooks/useApi/useApiHooks";
import { IAcademicYear } from "../academic-years/types";
import { get } from "lodash";
import { AxiosResponse } from "axios";
import { MonthlyPaymentTableHead } from "./components/MonthlyPaymentTableHead";
import useQueryHook from "hooks/useQueryHook";
import TablePagination from "components/elements/table/components/tablePagination";
import { TablePaginationWrapper } from "components/elements/table/components/tablePagination.style";
import { TableNavbar } from "./components/TableNavbar";
import { IStudent } from "pages/school/archiveStudents/types";
import { Payments } from "./components/Payments";

export interface IMonthlyPaymentProps { }

export const MonthlyPayment: FC<IMonthlyPaymentProps> = ({ }) => {
  const { data } = useApi<AxiosResponse<IAcademicYear[]>>(
    "academic-year",
    undefined
  );
  const [total, setTotal] = useState<number | undefined>();
  const { AddQueryParams, QueryParams } = useQueryHook();
  const { data: students } = useApi<IStudent>("/students/pagin", {
    page: get(QueryParams, "page", 1),
    limit: get(QueryParams, "limit", 20),
    state: "active",
  });

  useEffect(() => {
    setTotal(get(data, "data.total"));
  }, [students]);

  useEffect(() => {
    if (!QueryParams?.selectedYear) {
      const academicYears: IAcademicYear[] = get(data, "data.data", []);
      if (academicYears.length) {
        AddQueryParams({ selectedYear: academicYears[0]._id });
      }
    }
  }, [data]);

  return (
    <>
      <TableNavbar data={get(data, "data.data") as IAcademicYear[]} />

      <div className="h-[calc(100%-164px)] bg-bgMain">
        <TableV2>
          {get(students, "data.data", []).length && <MonthlyPaymentTableHead />}
          {get(students, "data.data", []).map(
            (student: IStudent, index: number) => (
              <TableRow>
                <TableCell
                  className="sticky left-0 z-10 bg-[#F5F5F5]"
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  className="sticky left-[30px] z-10 bg-[#F5F5F5]"
                >
                  {student.contractNumber}
                </TableCell>
                <TableCell
                  className="sticky left-[130px] z-10 bg-[#F5F5F5]"
                >
                  {`${student.firstName} ${student.lastName}`}
                </TableCell>
                <TableCell
                  className="sticky left-[240px] z-10 bg-[#F5F5F5]"
                >
                  {`${student.class?.grade}-${student.class?.letter}`}
                </TableCell>
                <TableCell
                  className="sticky left-[280px] z-10 bg-[#F5F5F5]"
                >
                  {student.phoneNumber}
                </TableCell>
                <Payments student={student} />
              </TableRow>
            )
          )}
        </TableV2>
      </div>
      <TablePaginationWrapper>
        <TablePagination
          totalData={total as number}
          tableData={get(data, "data.data", [])}
          defaultLimit={10}
        />
      </TablePaginationWrapper>
    </>
  );
};
