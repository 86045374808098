import { forwardRef, TdHTMLAttributes } from "react";
import { cn } from "utils/classNames";

export const TableCell = forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px] border",
      className
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";
