import { useApiMutation } from "hooks/useApi/useApiHooks";
import { FC, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPaymentStatus } from "../types";
import { TextInput } from "components";

interface IPaymentStatusForm {
  formStore: UseFormReturn<IPaymentStatus>;
  edit?: IPaymentStatus;
  resetForm: () => void;
}

const PaymentStatusForm: FC<IPaymentStatusForm> = ({
  formStore,
  edit,
  resetForm,
}) => {
  const { control, handleSubmit, reset, setValue } = formStore;

  const { mutate, status, data } = useApiMutation<IPaymentStatus>(
    "payment-status",
    edit ? "put" : "post"
  );

  const submit = (data: IPaymentStatus) => {
    mutate({ ...data });
  };

  useEffect(() => {
    if (status === "success") {
      resetForm();
    }
  }, [status, data]);

  useEffect(() => {
    if (edit) {
      reset({
        _id: edit._id,
        color: edit.color,
        text: edit.text,
      });
    }
  }, [edit]);
  const { t } = useTranslation();

  return (
    <form
      id="add-payment-status"
      className="w-96"
      onSubmit={handleSubmit(submit)}
    >
      <TextInput
        control={control}
        name="text"
        label={t("general.name")!}
      />
      <TextInput
        control={control}
        name="color"
        label={t("general.color")!}
        type="color"
        rules={{ required: false }}
      />
    </form>
  );
};

export default PaymentStatusForm;
