import { useEffect, useState } from "react";
import { ConfigProvider, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import "dayjs/locale/ru";
import locale from "antd/locale/ru_RU";
import { RangeDatePickerStyled } from "./RangeDatePicker.style";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";
import { useTranslation } from "react-i18next";

type RangePickerValue = [Dayjs, Dayjs] | null;

const RangeDatePicker = ({
  filterable = true,
  placeholder = ["general.startDate", "general.endDate"],
  onchange,
  clearable = true,
  defaultValues,
}: {
  filterable?: boolean;
  placeholder?: [string, string];
  onchange?: any;
  clearable?: boolean;
  defaultValues?: RangePickerValue;
}) => {
  const [date, setDate] = useState<RangePickerValue>(
    defaultValues ? defaultValues : null
  );
  const [_, setSearchParams] = useSearchParams();
  const allParams = useAllQueryParams();

  const onDateChange = (value: RangePickerValue) => {
    setDate(value);
    if (filterable) {
      if (value === null) {
        delete allParams?.fromDate;
        delete allParams?.toDate;
        setSearchParams({
          ...allParams,
        });
      }
      if (value && dayjs(value?.[0]).isValid() && dayjs(value?.[1]).isValid()) {
        setSearchParams({
          ...allParams,
          fromDate: String(dayjs(value[0]).startOf("day").toISOString()),
          toDate: String(dayjs(value[1]).endOf("day").toISOString()),
        });
      }
    }
  };

  useEffect(() => {
    if (allParams.fromDate && allParams.toDate) {
      setDate([dayjs(allParams.fromDate), dayjs(allParams.toDate)]);
    } else if (defaultValues?.[0] && defaultValues?.[1]) {
      setDate(defaultValues);
      setSearchParams({
        ...allParams,
        fromDate: String(dayjs(defaultValues[0]).startOf("day").toISOString()),
        toDate: String(dayjs(defaultValues[1]).endOf("day").toISOString()),
      });
    }
  }, []);

  const { t } = useTranslation();

  return (
    <RangeDatePickerStyled>
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          value={date as any}
          // @ts-ignore
          onChange={(val: RangeValue<Dayjs>) => {
            onDateChange(val);
            onchange?.(val);
          }}
          allowClear={clearable}
          // disabledDate={(current) => {
          //   return current && current > dayjs().endOf("day");
          // }}
          placeholder={[t(placeholder[0]), t(placeholder[1])]}
          inputReadOnly
        />
      </ConfigProvider>
    </RangeDatePickerStyled>
  );
};

export default RangeDatePicker;
