import { Input } from "antd";
import { FC, KeyboardEvent } from "react";
import { IPayment } from "../types";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { queryClient } from "services/client/query.config";
import { useTranslation } from "react-i18next";

export interface IPutPaymentComentProps {
  foundPayment: IPayment | undefined;
}

export const PutPaymentComent: FC<IPutPaymentComentProps> = ({
  foundPayment,
}) => {
  const { t } = useTranslation();
  const { mutateAsync } = useApiMutation("payment", "put");
  return (
    <Input
      disabled={!foundPayment?.status?._id}
      onPressEnter={(event: KeyboardEvent<HTMLInputElement>) => {
        if (foundPayment) {
          mutateAsync({
            _id: foundPayment._id,
            statusId: foundPayment.status._id,
            comment: (event.target as HTMLInputElement).value,
          }).then(() => {
            queryClient.invalidateQueries(["academic-year"]);
            event.currentTarget.blur();
          });
        }
      }}
      placeholder={t("general.empty_field")}
      className="inline-block w-48"
      type="text"
      defaultValue={foundPayment?.comment}
    />
  );
};
