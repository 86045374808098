import { Drawer } from "@mui/material";
import { CloseIcon, TeacherIcon } from "assets/svgs";
import { CommonModal, Select } from "components";
import CommonButton from "components/common/commonButton/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setThemeState } from "store/reducers/LoginSlice";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { CloseBtn } from "styles/global.style";
import { TSetState } from "types/form.types";
import NavSelectIcon from "../../../assets/images/NavSelectIcon";
import { LanguageBox, SettingDrawerStyle } from "./Sidebar.style";
import { useSocket } from "pages/chat/hooks/useSocket";

interface ISettingDrawer {
  open: boolean;
  setOpen: TSetState<boolean>;
}
const SettingDrawer = ({ open, setOpen }: ISettingDrawer) => {
  const [logout, setLogout] = useState<string>("");
  const lang = localStorage.getItem("i18nextLng");
  const [language, setLanguage] = useState<string>(lang || "uz");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const socket = useSocket();

  const { firstName, lastName, type, _id, branchEmployees } = useAppSelector(
    (state) => state.LoginState.loginData
  );
  const currentBranch = localStorage.getItem("branchId");
  const dis = useAppDispatch();

  const onClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    const themeMode = localStorage.getItem("theme") || "light";
    localStorage.clear();
    navigate("/login");
    dis(setThemeState(themeMode));
    setLogout("");

    socket?.disconnect();
  };

  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const handleChangeBranch = (branch: string) => {
    localStorage.setItem("branchId", branch);
    navigate(0);
  };
  const themeChecked = localStorage.getItem("theme") === "dark";
  return (
    <div>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            borderRadius: "0 16px 16px 0",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        }}
        BackdropProps={{
          sx: {
            backdropFilter: "blur(2px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        <SettingDrawerStyle>
          <div className="top">
            <div className="header">
              <h2>{t("general.account")}</h2>
              <CloseBtn onClick={() => setOpen(false)}>
                <CloseIcon />
              </CloseBtn>
            </div>
            <div className="section">
              <div className="head">
                <TeacherIcon />
                <p
                  className="d-flex flex-column"
                  onClick={() => {
                    navigate(`/employees/info/${_id}`);
                    setOpen(false);
                  }}
                >
                  {firstName} {lastName}
                  <span>
                    {type === "moderator"
                      ? t("general.moderator")
                      : t("general.teacher")}
                  </span>
                </p>
              </div>

              <Select
                iconValue={<NavSelectIcon />}
                // label={t("general.branch")!}
                options={branchEmployees?.map((rol) => ({
                  _id: rol.branchId,
                  name: rol.branch.name,
                }))}
                defaultFirst={false}
                customValue={currentBranch || branchEmployees?.[0].branch?._id}
                onChange={(val) => handleChangeBranch(val as string)}
              />

              <label htmlFor="theme" className="theme_label">
                <label className="theme_title">{t(`general.theme`)}</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="theme"
                    checked={themeChecked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dis(setThemeState("dark"));
                      } else dis(setThemeState("light"));
                    }}
                  />
                  <span className="slider"></span>
                </label>
              </label>
            </div>
          </div>
          <div className="bottom">
            <LanguageBox>
              <button
                className={language === "uz" ? "active" : ""}
                onClick={() => handleChangeLang("uz")}
              >
                UZ
              </button>
              <button
                className={language === "ru" ? "active" : ""}
                onClick={() => handleChangeLang("ru")}
              >
                RU
              </button>
              <button
                className={language === "en" ? "active" : ""}
                onClick={() => handleChangeLang("en")}
              >
                EN
              </button>
            </LanguageBox>
            <CommonButton
              title={t("general.logout")!}
              sx={{
                width: "100%",
                backgroundColor: "rgba(35, 120, 144,0.1) !important",
                color: "#EB5757 !important",
              }}
              onClick={() => setLogout("wantLogout")}
            />
          </div>
        </SettingDrawerStyle>
      </Drawer>

      <CommonModal
        title={t("general.realy_logout")!}
        open={!!logout}
        setOpen={setLogout}
        onSave={handleLogout}
      />
    </div>
  );
};

export default SettingDrawer;
