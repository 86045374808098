import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type ILoginData = typeof initialState.loginData;
export type IThemeState = typeof initialState.themeState;
const initialState = {
  isAuth: false,
  themeState: localStorage.getItem("theme") || "light",
  loginData: {
    _id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    fullName: "",
    isBoss: false,
    password: "",
    token: "",
    currentBranch: {
      _id: "",
      name: "",
    },
    branchEmployees: [
      {
        _id: "",
        employeeId: "",
        roleId: "",
        branchId: "",
        isActive: false,
        role: {
          _id: "",
          name: "",
          getClasses: false,
          editClasses: false,
          deleteClasses: false,
          getSubjects: false,
          editSubjects: false,
          deleteSubjects: false,
          getRooms: false,
          editRooms: false,
          deleteRooms: false,
          getStudents: false,
          editStudents: false,
          deleteStudents: false,
          cancelStudentSubscription: false,
          // getDashboard: false,
          getLessons: false,
          editLesson: false,
          deleteLesson: false,
          getJournal: false,
          getRoles: false,
          editRoles: false,
          deleteRoles: false,
          getEmployees: false,
          editEmployees: false,
          deleteEmployees: false,
          createChatSettings: false,
          updateChatSettings: false,
          getChatSettingss: false,
          deleteChatSettings: false,
          getSettings: false,
          editSettings: false,
          deleteSettings: false,
          getBranch: false,
          editBranch: false,
          deleteBranch: false,
          getBuilding: false,
          editBuilding: false,
          deleteBuilding: false,
          editClassStudent: false,
          deleteClassStudent: false,
          getPeriod: false,
          editPeriod: false,
          getReceipt: false,
          updateReceipt: false,
          setReceipt: false,
          getQuarter: false,
          editQuarter: false,
          transactionDelete: false,
          getAttendance: false,
          setAttendance: false,
          getSelfClass: false,
          getSelfJounral: false,
          paymentMethodsGet: false,
          paymentMethodsEdit: false,
          paymentMethodsDelete: false,
          cashboxGet: false,
          cashboxEdit: false,
          cashboxDelete: false,
          transactionTypeGet: false,
          transactionTypeEdit: false,
          transactionTypeDelete: false,
          transactionsMakeIncome: false,
          transactionsMakeExpense: false,
          subscriptionGet: false,
          subscriptionEdit: false,
          subscriptionDelete: false,
          setStudentSubscription: false,
          getEmployeeSalaryData: false,
          createEmployeeSalary: false,
          deleteAttendance: false,
          createHoliday: false,
          updateHoliday: false,
          getHolidays: false,
          deleteHoliday: false,
          cashboxTransfer: false,
          cashboxBranch: false,
          getMarksAvarageAnalitics: false,
          getQuarterMarkAnalitics: false,
          getMarksSystem: false,
          createSeasonalMark: false,
          updateSeasonalMark: false,
          getSeasonalMarks: false,
          deleteSeasonalMark: false,
          getClassSeasonalMarkAnalitics: false,
          getChats: false,
          getParents: false,
          getTeacherWorkReport: false,
          getSmsTemplate: false,
          getIntegration: true,
          getEmployeeTaskTypes: false,
          createEmployeeTaskType: false,
          updateEmployeeTaskType: false,
          deleteEmployeeTaskType: false,
          viewAcademicYear: false,
          manageAcademicYear: false,
          managePaymentStatus: false,
          viewPaymentStatus: false,
          viewPayment: false,
          managePayment: false,
        },
        branch: {
          _id: "",
          name: "",
        },
      },
    ],
    type: "",
  },
};

const LoginSlice = createSlice({
  name: "login-data",
  initialState,
  reducers: {
    setLoginData(state, payload: PayloadAction<ILoginData>) {
      state.loginData = payload.payload;
      state.isAuth = true;
    },
    setThemeState(state, payload: PayloadAction<IThemeState>) {
      state.themeState = payload.payload;
      localStorage.setItem("theme", state.themeState);
    },
  },
});

export const { setLoginData, setThemeState } = LoginSlice.actions;

export default LoginSlice.reducer;
