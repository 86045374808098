import { TableCell, TableRow } from "components";
import { useGetMonths } from "hooks/useGetMonths";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface IMonthlyPaymentTableHeadProps { }

export const MonthlyPaymentTableHead: FC<
  IMonthlyPaymentTableHeadProps
> = ({ }) => {
  const { t } = useTranslation();
  const months = useGetMonths();
  return (
    <>
      <TableRow>
        <TableCell align="center" className="font-bold sticky left-0 z-10 bg-[#F5F5F5]" rowSpan={2}>
          №
        </TableCell>
        <TableCell  align="center" className="font-bold sticky left-[30px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.CONTRACT_NUMBER")}
        </TableCell>
        <TableCell align="center" className="font-bold sticky left-[130px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.NAME_SURNAME")}
        </TableCell>
        <TableCell align="center" className="font-bold sticky left-[240px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.CLASS")}
        </TableCell>
        <TableCell align="center" className="font-bold sticky left-[280px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.PHONE_NUMBER")}
        </TableCell>
        {months.map((item) => (
          <TableCell align="center" className="font-bold" colSpan={2}>
            <p className="text-nowrap"> {`${item.month} ${item.year}`}</p>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {months.map(() => (
          <>
            <TableCell className="font-bold" align="center">
              {t("general.status")}
            </TableCell>
            <TableCell className="font-bold" align="center">
              {t("general.comment")}
            </TableCell>
          </>
        ))}
      </TableRow>
    </>
  );
};
