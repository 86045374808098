import { DatePickerV2 } from "components/common/DatePickerV2";
import dayjs from "dayjs";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { IAcademicYear } from "pages/settings/academic-years/types";
import { FC, useEffect } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IAcademicYearsForm {
  formStore: UseFormReturn<IAcademicYear>;
  edit?: IAcademicYear;
  resetForm: () => void;
}

const AcademicYearsForm: FC<IAcademicYearsForm> = ({
  formStore,
  edit,
  resetForm,
}) => {
  const { control, handleSubmit, reset, setValue } = formStore;

  const { mutate, status, data } = useApiMutation<IAcademicYear>(
    "academic-year",
    edit ? "put" : "post"
  );

  const submit = (data: IAcademicYear) => {
    mutate({ ...data });
  };

  useEffect(() => {
    if (status === "success") {
      resetForm();
    }
  }, [status, data]);

  useEffect(() => {
    if (edit) {
      reset({
        _id: edit._id,
        createdAt: edit.createdAt,
        endsAt: edit.endsAt,
        startsAt: edit.startsAt,
      });
    }
  }, [edit]);
  const { t } = useTranslation();
  const startsAt = useWatch({ control, name: "startsAt" });

  return (
    <form
      id="add-academic-years"
      className="w-full"
      onSubmit={handleSubmit(submit)}
    >
      <DatePickerV2 name="startsAt" title={`${t("general.startsAt")}:`} />
      <DatePickerV2
        disabled={!startsAt}
        name="endsAt"
        title={`${t("general.endsAt")}:`}
        minDate={dayjs(startsAt) as any}
      />
    </form>
  );
};

export default AcademicYearsForm;
