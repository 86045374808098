import { useTranslation } from "react-i18next";
import useQueryHook from "./useQueryHook";
import { useApi } from "./useApi/useApiHooks";
import { IAcademicYear } from "pages/settings/academic-years/types";
import { AxiosResponse } from "axios";

export interface IMonth {
  month: string;
  year: number;
  monthNumber: number;
}
export const useGetMonths = (): IMonth[] => {
  const { QueryParams } = useQueryHook();
  const { data } = useApi<AxiosResponse<IAcademicYear[]>>("academic-year");
  const foundAcademicYear = data?.data?.data?.find(
    (item: IAcademicYear) => item._id === QueryParams.selectedYear
  );

  const { t } = useTranslation();
  const monthNames = [
    t("general.january"),
    t("general.february"),
    t("general.march"),
    t("general.april"),
    t("general.may"),
    t("general.june"),
    t("general.july"),
    t("general.august"),
    t("general.september"),
    t("general.october"),
    t("general.november"),
    t("general.december"),
  ];

  function getMonths(): IMonth[] {
    const start = new Date(foundAcademicYear?.startsAt!);
    const end = new Date(foundAcademicYear?.endsAt!);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error("Invalid date format. Please provide valid dates.");
    }
    if (start > end) {
      console.error("Start date must be earlier than end date.");
    }

    const months = [];

    let current = new Date(start);

    while (current <= end) {
      months.push({
        month: monthNames[current.getMonth()],
        year: current.getFullYear(),
        monthNumber: current.getMonth() + 1,
      });
      current.setMonth(current.getMonth() + 1);
    }

    return months;
  }

  return getMonths();
};
