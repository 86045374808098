import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { Control, Controller, FieldPath } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TRules } from "types/form.types";
import { StyledDateMonthPicker } from "./style";

const disabledDate = (
  current: Dayjs,
  minDate: Date | undefined,
  maxDate: Date | undefined
) => {
  if (!current) {
    return false;
  }
  const currentDate = dayjs(current);
  if (minDate && maxDate) {
    return (
      currentDate.isBefore(minDate, "day") ||
      currentDate.isAfter(maxDate, "day")
    );
  }
  if (minDate) {
    return currentDate.isBefore(minDate, "day");
  }
  if (maxDate) {
    return currentDate.isAfter(maxDate, "day");
  }
  return currentDate > dayjs().endOf("day");
};

interface IDatePicker {
  control: Control<any>;
  name: FieldPath<any>;
  rules?: TRules<any>;
  label?: string;
  disableTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
  clearable?: boolean;
  type?: "month" | "year";
}

const DateMonthPicker = ({
  name,
  control,
  rules = {
    required: {
      value: true,
      message: "general.required",
    },
  },
  label,
  clearable,
  type = "month",
  minDate,
  maxDate,
}: IDatePicker) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => (
        <>
          <StyledDateMonthPicker>
            <span className="label">
              {label}
              {!!rules?.required && <span className="text-error">*</span>}
            </span>
            <DatePicker
              {...field}
              picker={type}
              // disabledDate={(current) =>
              //   disabledDate(current, minDate, maxDate)
              // }
              allowClear={clearable}
              // placeholder={t("general.select")!}
            />
          </StyledDateMonthPicker>
          {error && (
            <>
              <h6 className="text-error mt-1">
                {label} {t(error.message!)}
              </h6>
            </>
          )}
        </>
      )}
    />
  );
};

export default DateMonthPicker;
