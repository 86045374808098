import { useQuery } from "react-query";
import { IMonthlyPayment } from "../types";
import { AxiosResponse } from "axios";
import api from "services/client/client";

export const useGetPayments = (selectedYear: string) => {
  const response = useQuery<Record<string, IMonthlyPayment>>(
    ["payments", selectedYear],
    async () => {
      try {
        const response = await api.get<AxiosResponse<IMonthlyPayment[]>>(
          "payment/academic-year",
          {
            params: {
              page: 1,
              limit: 50,
              academicYearId: selectedYear,
            },
          }
        );
        const mutatedData: Record<string, IMonthlyPayment> = {};

        response.data.data.forEach(
          (item) => (mutatedData[item.student._id] = item)
        );

        return mutatedData;
        // @ts-ignore
      } catch (error: AxiosResponse<Data>) {
        if (error?.statusCode === 401) {
          window.location.replace("/login");
          return error;
        }

        return {
          data: [],
        };
      }
    },

    {
      enabled: !!selectedYear,
    }
  );
  return response;
};
