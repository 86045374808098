import { Button } from "@mui/material";
import { IconButton } from "@mui/material";
import styled from "styled-components";

export const ButtonStyled = styled(Button)`
  height: 42px !important;
  min-width: 110px !important;
  max-width: 350px !important;
  border-radius: 8px !important;
  outline: none !important;
  font-size: 0.9 !important;
  text-transform: inherit !important;
  padding: 0 18px !important;
  background-color: ${({ theme }) => theme.hover.main} !important;
  color: ${({ theme }) => theme.primary.dark};
  line-height: 16px !important;
  font-weight: 500 !important;

  &.designed {
    background: linear-gradient(90deg, #ff5c4d 0%, #eb469f 40%, #8341ef 100%);
    color: ${({ theme }) => theme.primary.light} !important;

    .MuiButton-startIcon {
      transition: all 0.2s ease;
      path {
        stroke: ${({ theme }) => theme.primary.light} !important;
      }
    }
  }

  &.grey {
    background: ${({ theme }) => theme.hover.main} !important;
    color: ${({ theme }) => theme.primary.dark} !important;
  }

  &.accept {
    background-color: #3e5189 !important;
    color: ${({ theme }) => theme.primary.light} !important;
  }
  &.complete {
    background-color: #17c657 !important;
    color: ${({ theme }) => theme.primary.light} !important;
  }
  &.cancel {
    background-color: ${({ theme }) => theme.background.primary} !important;
    color: ${({ theme }) => theme.primary.light} !important;
  }

  &.main {
    background-color: ${({ theme }) => theme.background.primary} !important;
    color: #fff;

    &:disabled {
      color: ${({ theme }) => theme.primary.disabled} !important;
      background-color: ${({ theme }) => theme.background.disabled} !important;
    }
  }

  &.back {
    background-color: ${({ theme }) => theme.hover.main} !important;
    color: ${({ theme }) => theme.background.primary} !important;

    svg {
      path {
        fill: ${({ theme }) => theme.background.primary} !important;
      }
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.primary.disabled} !important;
    background-color: ${({ theme }) => theme.background.disabled};
    cursor: copy;
  }
  &.color-blue {
    color: #0165ff !important;
    background-color: ${({ theme }) => theme.hover.main} !important;
  }
  &.color-red {
    color: #f05252 !important;
    background-color: ${({ theme }) => theme.hover.main} !important;
  }
  &.color-orange {
    color: #FF8A32 !important;
    background-color: ${({ theme }) => theme.hover.main} !important;
  }

  &.import {
    color: ${({ theme }) => theme.background.primary} !important;
    border: 1px solid ${({ theme }) => theme.hover.main}!important;
    background-color: ${({ theme }) => theme.hover.main} !important;

    svg {
      path {
        stroke: ${({ theme }) => theme.primary.main};
      }
    }
  }
  &.export {
    color: #fff !important;
    border: 1px solid ${({ theme }) => theme.hover.main}!important;
    background-color: ${({ theme }) => theme.background.primary} !important;
    .MuiCircularProgress-svg {
      circle {
        stroke: ${({ theme }) => theme.background.main};
      }
    }
  }
  &.tab {
    background-color: ${({ theme }) => theme.hover.main} !important;
    color: ${({ theme }) => theme.background.primary} !important;

    &.active {
      background-color: ${({ theme }) => theme.background.primary} !important;
      color: #fff !important;

      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }

  .MuiCircularProgress-svg {
    circle {
      stroke: ${({ theme }) => theme.background.primary};
    }
  }
`;

export const IconButtonStyled = styled(IconButton)`
  width: 36px !important;
  height: 36px !important;
  background-color: ${({ theme }) => theme.background.hover} !important;
`;
