import styled from "styled-components";

export const TablePaginationWrapper = styled.div<{
  tableHeight?: string;
  isGetAll?: boolean;
  padding?: number;
}>`
  .pagination_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 68px;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.border.main};
    background-color: ${({ theme }) => theme.background.main};
    gap: 20px;
    border-radius: 0 0 8px 8px;

    .pag_title {
      min-width: 145px;
      height: 48px;
      border: 1px solid ${({ theme }) => theme.border.main};
      background-color: ${({ theme }) => theme.background.main};
      display: flex;
      justify-content: center;
      color: ${({ theme }) => theme.primary.dark};
      font-size: 15px;
      font-weight: 700;
      align-items: center;
      gap: 5px;
      border-radius: 16px;
      cursor: pointer;

      svg {
        circle {
          fill: ${({ theme }) => theme.background.primary} !important;
        }
      }
    }
    .MuiButtonBase-root {
      width: 40px;
      height: 40px;
      border-radius: 16px;
      color: #bdbdbd;
      font-weight: 600;
      border: 1px solid ${({ theme }) => theme.border.main};

      &:hover {
        background-color: ${({ theme }) => theme.background.dark};
      }

      &.Mui-selected {
        border: 1px solid ${({ theme }) => theme.border.main};
        background-color: ${({ theme }) => theme.background.primary};
        color: #fff;
      }
    }
  }
`;
