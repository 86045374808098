import { Box, Collapse, IconButton } from "@mui/material";
import styled, { createGlobalStyle } from "styled-components";

export const SIDEBAR_OPEN = "280px";
export const SIDEBAR_CLOSE = "103px";

// ? Colors
export const lightMode = {
  primaryColor: "#2a363b",
  primaryColor2: "#3f4f56",

  darkColor: "#000",
  whiteColor: "#fff",
  whiteColor2: "#eee",
  whiteColor3: "#E8E8E8",
  inputColor: "#f4fff1",
  paragColor: "#A1A1A1",
  checkPointBackground: "#fafafa",
  borderBottomColor: "#e8e8e8",
  ProductItemBackColor: "#fafafa",
};

export const darkMode = {
  primaryColor: "rgb(0, 7, 61)",
  darkColor: "#fff",
  whiteColor: "#0E1621",
  whiteColor2: "#0E1621",
  whiteColor3: "#0E1621",
  inputColor: "#242F3D",
  paragColor: "#eee",
  checkPointBackground: "#17212B",
  borderBottomColor: "#17212B",
  ProductItemBackColor: "#17212B",
};

// ? Font size
export const buttonSize = "16px";
export const paragraphSize = "14px";
export const miniSize = "12px";

// ? Font weight
export const ButtonWeight = "500";
export const sixHundred = "600";

export const GlobalStyle = createGlobalStyle`
     * {
          margin: 0;
          padding: 0;
          outline:0;
          box-sizing: border-box;
          font-family: "Gilroy";
     }

     body {
          :not(:root):fullscreen::backdrop {
               position: fixed;
               top: 0px;
               right: 0px;
               bottom: 0px;
               left: 0px;
               /* background: #F6F6F9; */
          }
     }
     #root{
          margin:0 auto;
     }

     p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
     }
    
   
`;

export const Container = styled.div`
  /* padding-left: 250px !important; */
`;

export const PrivateContainer = styled.div`
  /* background: #ffffff; */
`;

export const BackGroundColorContainer = styled.div`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.background.main};
  border-radius: 0.5rem;
`;

export const AddEditImgContainer = styled.div`
  padding: 1.5rem;
  border-radius: 4px;
  border: 1px solid #eaeaef;
  background-color: ${({ theme }) => theme.background.main};

  .btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 2rem;
  }
`;

export const SecondRowShow = styled(Collapse)`
  .ant-picker {
    background-color: #f5f5f5;
    width: 100% !important;
  }
  .filterBox {
    display: flex;
    justify-content: flex-end;
  }
`;

export const CustomLink = styled.span`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.background.primary};
  }
`;

export const RemoveIconStyled = styled.div`
  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.background.error};
    svg {
      path {
        fill: ${({ theme }) => theme.error.main};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.background.error};

      svg {
        path {
          fill: ${({ theme }) => theme.error.dark};
        }
      }
    }
  }
`;

export const ChangeIconStyled = styled.div`
  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.background.error};
    svg {
      path {
        fill: black;
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.background.error};

      svg {
        path {
          fill: ${({ theme }) => theme.error.dark};
        }
      }
    }
  }
`;

export const ConfirmIconStyled = styled.div`
  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.success.main};
    svg {
      path {
        fill: ${({ theme }) => theme.background.main};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.success.main};
      svg {
        path {
          fill: ${({ theme }) => theme.error.dark};
        }
      }
    }
  }
`;


export const OrderHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  /* width: 500px; */
  justify-content: flex-end;
  .MuiFormControl-root {
    width: 140px;
    height: 48px !important;
    padding: 0;
    font-size: 14px;
    background-color: #f5f5f5;
    border-radius: 12px;
    border: none !important;
    label {
      display: none !important;
    }

    input {
      font-size: 14px;
      font-weight: 500;
    }
    .MuiSvgIcon-root {
      color: #0f6fdf;
    }
  }
  .MuiSelect-select {
    border: none !important;
    height: 100% !important;
  }
  .MuiInputBase-root {
    border: none !important;
    /* background-color: aqua; */
    height: 48px !important;
  }
  .filter_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 140px;
    height: 42px;
    padding: 0 15px;
    background-color: ${({ theme }) => theme.hover.main} !important;
    border-radius: 12px;
    color: #232323;
    text-transform: inherit;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;

    span {
      color: ${({ theme }) => theme.primary.dark};
    }

    svg {
      path {
        stroke: ${({ theme }) => theme.primary.main};
      }
    }

    &:hover {
      background-color: #eeeeee;
    }

    .icon {
      transition: all 0.3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }

    div {
      display: flex;
      align-items: center;
      gap: 7px;
      height: 100%;
    }
  }
`;

export const HeaderButtons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;
export const CustomScheduleTitle = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  span {
    font-size: 16px;
  }

  .class_name {
    code {
      color: #000;
      text-transform: lowercase;
    }
  }
`;
export const MainHeader = styled(Box)`
  height: 72px;
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.background.main};
  z-index: 2;
  -webkit-box-shadow: 0px 4px 8px -6px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 4px 8px -6px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 4px 8px -6px rgba(34, 60, 80, 0.2);

  h2 {
    color: ${({ theme }) => theme.primary.dark};
  }
`;

export const CustomIconButtonStyled = styled(IconButton)`
  max-height: 42px !important;
  max-width: 42px !important;
  border-radius: 10px !important;
  background-color: ${({ theme }) => theme.hover.main} !important;

  svg {
    path {
      fill: ${({ theme }) => theme.primary.dark};
    }
  }
`;
export const ErrorLabelStyle = styled.div`
  color: #ea5455;
  font-size: 12px;
`;

export const LinkTag = styled.span`
  font-size: 14px;
  cursor: pointer;
  color: #000;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.background.primary};
  }
`;

export const CloseBtn = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  margin-left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
  transition: all 0.3s ease;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.primary.dark};
    }
  }
`;

export const ImgUploadStyled = styled(Box)`
  position: relative;
  width: 120px;
  height: 110px;
  border-radius: 10px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    input {
      opacity: 0;
      z-index: -1;
    }
  }

  .imageBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
    .deleteIcon {
      z-index: -1;
      opacity: 0;
      transition: all 0.3s ease;
      position: absolute;
      width: 35px;
      height: 35px;
      background-color: red;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  &:hover {
    .imageBox {
      .deleteIcon {
        z-index: 2;
        opacity: 1;
      }
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.055);
  }
`;

export const ScheduleHoverBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .item {
    display: flex;
    justify-content: space-between;
    gap: 7px;
    max-width: 300px;

    code {
      font-size: 13px;
      font-weight: 600;
      color: #999;
    }
    p {
      font-size: 13px;
      font-weight: 700 !important;
      width: 180px;
      color: ${({ theme }) => theme.background.primary};
    }
  }
`;

export const StyledCustomCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 42px;

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .ant-checkbox-input {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
`;

export const AppBar = styled.div`
  transition: all 0.3s ease;

  * {
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.scrollbar.thumb};
      border-radius: 12px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.scrollbar.track};
    }
  }

  body {
    background-color: ${({ theme }) => theme.background.body};
  }

  .main-container {
    min-height: 100%;
    animation: animate 0.3s linear;
  }

  @keyframes animate {
    0% {
      filter: brightness(92%);
    }
    100% {
      filter: brightness(100%);
    }
  }
`;

export const ThreeDotStyle = styled(Box)`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const PrintIconButton = styled(IconButton)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background-color: ${({ theme }) => theme.background.body} !important;
`;
