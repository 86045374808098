import { Select } from "antd";
import { useApi, useApiMutation } from "hooks/useApi/useApiHooks";
import { IMonth } from "hooks/useGetMonths";
import useQueryHook from "hooks/useQueryHook";
import { IStudent } from "pages/school/students/types";
import { IPaymentStatus } from "pages/settings/payment-status/types";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { queryClient } from "services/client/query.config";
import "../style.css";

export interface IPostPaymentStatusProps {
  student: IStudent;
  month: IMonth;
}

export const PostPaymentStatus: FC<IPostPaymentStatusProps> = ({
  student,
  month,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: post } = useApiMutation("payment", "post");
  const { data } = useApi<IPaymentStatus[]>("payment-status/all", {
    page: 1,
    limit: 100,
  });
  const { QueryParams } = useQueryHook();

  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    color: string;
  } | null>(null);

  return (
    <Select
      value={selectedOption?.value}
      onChange={(value) => {
        const selectedItem = data?.data?.find((item) => item._id === value);
        setSelectedOption({
          value,
          color: selectedItem?.color || "transparent",
        });

        post({
          studentId: student._id,
          statusId: value,
          academicYearId: QueryParams.selectedYear,
          month: month.monthNumber,
        }).then(() => {
          queryClient.invalidateQueries(["academic-year"]);
          queryClient.invalidateQueries(["payments"]);
        });
      }}
      placeholder={t("general.not_paid")}
      className="inline-block w-48"
      style={{
        backgroundColor: selectedOption?.color || "transparent",
      }}
      options={data?.data?.map((item) => ({
        label: item.text,
        value: item._id,
        style: { backgroundColor: item.color },
      }))}
    />
  );
};
