import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonModal, FormDrawer, Table } from "components";
import { useAcademicYearsColumns } from "pages/settings/academic-years/academicYears.columns";
import { useRoleManager } from "services/useRoleManager";
import { IAcademicYear } from "pages/settings/academic-years/types";
import { FormProvider, useForm } from "react-hook-form";
import AcademicYearsForm from "./components/AcademicYearsForm";
import { useAppDispatch } from "store/storeHooks";
import { setOpenDrawer } from "components/elements/formDrawer/formdrawer.slice";
import { useApiMutation } from "hooks/useApi/useApiHooks";

export interface IAcademicYearsProps {}

export const AcademicYears: FC<IAcademicYearsProps> = ({}) => {
  const { t } = useTranslation();
  const columns = useAcademicYearsColumns();
  const hasAccess = useRoleManager();
  const [remove, setRemove] = useState<string>();
  const [edit, setEdit] = useState<IAcademicYear | null>(null);
  const formStore = useForm<IAcademicYear>();
  const dis = useAppDispatch();

  const { mutateAsync, status } = useApiMutation(
    `academic-year/${remove}`,
    "delete",
    {}
  );

  function resetForm() {
    setEdit(null);
    formStore.reset({
      _id: undefined,
      createdAt: undefined,
      endsAt: undefined,
      startsAt: undefined,
    });
  }

  return (
    <FormProvider {...formStore}>
      <Table
        onAddButton={
          hasAccess("manageAcademicYear")
            ? () => {
                dis(setOpenDrawer(true));
              }
            : undefined
        }
        dataUrl="/academic-year"
        columns={columns}
        onDeleteColumn={
          hasAccess("manageAcademicYear")
            ? (data: IAcademicYear) => {
                setRemove(data._id);
              }
            : undefined
        }
        onEditColumn={
          hasAccess("manageAcademicYear")
            ? (data: IAcademicYear) => {
                dis(setOpenDrawer(true));
                setEdit(data);
              }
            : undefined
        }
        title={t("TABLE.ACADEMIC_YEARS")}
      />
      <FormDrawer
        className="min-w-96"
        FORM_ID="add-academic-years"
        formItemTitle={
          edit ? t("general.edit_academic_year")! : t("general.academic_year")!
        }
        onClose={resetForm}
        isEditing={!!edit}
      >
        <AcademicYearsForm
          formStore={formStore}
          edit={edit as IAcademicYear}
          resetForm={resetForm}
        />
      </FormDrawer>

      <CommonModal
        title={t("general.realy_delete")!}
        open={!!remove}
        setOpen={setRemove}
        onSave={() =>
          mutateAsync({}).then(() => {
            setRemove(undefined);
          })
        }
        status={status}
      />
    </FormProvider>
  );
};
