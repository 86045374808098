import { GridColumns } from "@mui/x-data-grid";
import { get } from "lodash";
import { IAcademicYear } from "./types";
import { useTranslation } from "react-i18next";

export const useAcademicYearsColumns = (): GridColumns<IAcademicYear> => {
  const { t } = useTranslation();

  return [
    {
      field: "startsAt",
      headerName: t("general.startsAt")!,
      renderCell({ row }) {
        return new Date(get(row, "startsAt", "")).toLocaleDateString("ru");
      },
    },
    {
      field: "endsAt",
      headerName: t("general.endsAt")!,
      renderCell({ row }) {
        return new Date(get(row, "endsAt", "")).toLocaleDateString("ru");
      },
    },
  ];
};
