import { GridColumns } from "@mui/x-data-grid";
import { get } from "lodash";
import { IPaymentStatus } from "./types";
import { useTranslation } from "react-i18next";

export const usePaymentStatusColumns = (): GridColumns<IPaymentStatus> => {
  const { t } = useTranslation();

  return [
    {
      field: "text",
      headerName: t("general.name")!,
      renderCell({ row }) {
        return get(row, "text", "");
      },
    },
    {
      field: "color",
      headerName: t("general.color")!,
      renderCell({ row }) {
        return (
          <span
            style={{
              display: "block",
              width: "24px",
              height: "24px",
              borderRadius: "5px",
              backgroundColor: get(row, "color", ""),
            }}
          ></span>
        );
      },
    },
  ];
};
