import { TableCell } from "components";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IPayment } from "../types";
import { IStudent } from "pages/school/students/types";
import { useGetPayments } from "../hooks/useGetPayments";
import { PutPaymentStatus } from "./PutPaymentStatus";
import { PostPaymentStatus } from "./PostPaymentStatus";
import { PutPaymentComent } from "./PutPaymentComment";
import { Input } from "antd";
import useQueryHook from "hooks/useQueryHook";
import { useGetMonths } from "hooks/useGetMonths";

export interface IPaymentsProps {
  student: IStudent;
}

export const Payments: FC<IPaymentsProps> = ({ student }) => {
  const { t } = useTranslation();
  const { QueryParams } = useQueryHook();

  const { data } = useGetPayments(QueryParams.selectedYear);
  const months = useGetMonths();

  const payment = useMemo(() => {
    return (
      <>
        {months.map((month) => {
          let foundPayment: IPayment | undefined;
          if (data && data[student._id]) {
            foundPayment = data[student._id].payments.find(
              (item) => item.month === month.monthNumber
            );
          }

          return (
            <>
              <TableCell>
                {foundPayment ? (
                  <PutPaymentStatus foundPayment={foundPayment} />
                ) : (
                  <PostPaymentStatus month={month} student={student} />
                )}
              </TableCell>
              <TableCell>
                {foundPayment ? (
                  <PutPaymentComent foundPayment={foundPayment} />
                ) : (
                  <Input
                    disabled
                    placeholder={t("general.empty_field")}
                    className="inline-block w-48"
                    type="text"
                  />
                )}
              </TableCell>
            </>
          );
        })}
      </>
    );
  }, [data]);

  return payment;
};
