import { FC } from "react";

export interface INavbarProps {
  title: string;
  actionButtons?: JSX.Element;
}

export const Navbar: FC<INavbarProps> = ({ actionButtons, title }) => {
  return (
    <div className="p-4 flex justify-between items-center bg-bgMain">
      <h1>{title}</h1>
      {actionButtons}
    </div>
  );
};
