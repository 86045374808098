import AuthUser from "layout/AuthUser/AuthUser";
import Layout from "layout/Layout";
import React, { useEffect, useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { privateRoutes, teacherRoutes } from "routes/PrivateRoutes";
import { AppBar, GlobalStyle } from "styles/global.style";
import "./styles/common.css";
import "./styles/config.css";

import "./styles/index.css";

// import { ThemeProvider } from "style-components";

import { OfflinePopup } from "components";
import "dayjs/locale/ru";
import SuspenseSite from "layout/Suspense";
import Login from "pages/login";
import { useRoleManager } from "services/useRoleManager";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useAppSelector } from "store/storeHooks";
import { ThemeProvider } from "styled-components";
import { darkMode } from "styles/theme/dark";
import { lightMode } from "styles/theme/light";
import { cn } from "utils/classNames";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const App: React.FC = () => {
  const navigate = useNavigate();
  const hasToken = !!localStorage.getItem("token");
  const { isAuth, themeState } = useAppSelector((store) => store.LoginState);
  const { type } = useAppSelector((state) => state.LoginState.loginData);
  useEffect(() => {
    if (!isAuth && !hasToken) {
      navigate("/login");
    }
  }, [isAuth]);

  const hasAccess = useRoleManager();

  const theme = useMemo(() => {
    if (themeState === "light") {
      return lightMode;
    } else return darkMode;
  }, [themeState]);

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        className={cn(
          "main-container",
          themeState === "light" ? "light" : "dark",
          "text-primaryDark"
        )}
      >
        <OfflinePopup />
        <ToastContainer position="top-center" autoClose={1500} />
        <GlobalStyle />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<AuthUser />}>
            <Route path="/" element={<SuspenseSite />}>
              <Route path="/" element={<Layout />}>
                {type === "moderator" ? (
                  <Route path="/" element={<SuspenseSite />}>
                    {privateRoutes.map((route) => {
                      if (route.role && hasAccess(route.role))
                        return (
                          <Route
                            element={route.element}
                            path={route.path}
                            key={route.path}
                          />
                        );
                      else return null;
                    })}
                  </Route>
                ) : (
                  <Route path="/" element={<SuspenseSite />}>
                    {teacherRoutes.map((route) => {
                      if (route.role && hasAccess(route.role))
                        return (
                          <Route
                            element={route.element}
                            path={route.path}
                            key={route.path}
                          />
                        );
                      else return null;
                    })}
                  </Route>
                )}
              </Route>
            </Route>
          </Route>
        </Routes>
      </AppBar>
    </ThemeProvider>
  );
};

export default App;
